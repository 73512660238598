<template>
    <!-- 创新赛简介 -->
    <div>
        <Footer></Footer>
        <div class="content">
            <img src="../certificate/image/certifate.jpg"/>
            <div class="formBox">
                <el-form :inline="true" :model="formInline" :rules="rules" ref="formInline" class="demo-form-inline"
                    label-width="111px">
                    <el-row :gutter="18">
                        <el-col :span="8">
                            <el-form-item label="学校" prop="school">
                                <el-input v-model="formInline.school" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="formInline.name" type="text" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item>
                                <el-button id="inquireBtn" type="primary" @click="onSubmit('formInline')">点击查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <el-table v-loading="loading" border :data="data" style="width: 100%">
                <el-table-column show-overflow-tooltip="true" prop="school" label="参赛单位" min-width="100"/>
                <el-table-column prop="senior" label="届数" width="80" />
                <el-table-column show-overflow-tooltip="true" prop="province_group" label="赛区/组别" min-width="90" />
                <el-table-column prop="prize" label="奖项" width="90"/>
                <el-table-column show-overflow-tooltip="true" prop="player" label="参赛选手" min-width="90" />
                <el-table-column show-overflow-tooltip="true" prop="teacher" label="指导教师" min-width="90" />
                <el-table-column prop="certificate_num" label="证书编号" width="200" />
            </el-table>
            <div id="pagination">
                <el-pagination :small="moveBy" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :pager-count="pagerCount" :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
                    :layout="layout" :total="total">
                </el-pagination>
            </div>
            <div id="outSite">
                <el-link type="primary" href="https://www.ncie.org.cn/Category_1397/Index.aspx" target="blank" style="font-size: 16px;">点击跳转工信部人才交流中心证书查询平台</el-link>
            </div>
        </div>
        
        <!-- 版权所有 -->
        <Bottom></Bottom>
    </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import { userInfo } from "api/apis.js";
export default {
    name: 'Certificate',
    components: {
        Footer,
        Bottom
    },
    data() {
        return {
            formInline: {
                school: '',
                name: '',
            },
            rules: {
                school: [
                    { required: true, message: '请输入学校', trigger: 'blur' },
                    { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入名字', trigger: 'blur' },
                    { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
                ],
            },
            loading: false,
            data: [],
            total: 0,
            page: 1,
            pageSize: 10,
            device_width:375,
            pagerCount:5,
            layout:"total, prev,sizes,pager, next",
            moveBy:false
        }
    },
    created() {
        this.deviceWidth()
    },
    methods: {
        
        //数据查询
        query() {
            let dataobj = new FormData();
            dataobj.append("school", this.formInline.school);//学校
            dataobj.append("name", this.formInline.name);//名字
            dataobj.append("page", this.page);
            dataobj.append("limit", this.pageSize);
            this.loading = true;
            userInfo.certificate(dataobj).then(res => {
                this.loading = false;
                this.data = JSON.parse(res.data);
                this.total = res.count;
            })
        },
        deviceWidth() {
            this.device_width = window.screen.width;
            if(this.device_width>=375 && this.device_width <= 960) {
                this.moveBy = true;
                this.pagerCount = 3;
                this.layout = 'total, sizes,pager, next';
            }else {
                this.moveBy = false;
                this.pagerCount = 5;
                this.layout = 'total, prev,sizes,pager, next';
            }
        },
        //查询提交
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let dataobj = new FormData();
                    dataobj.append("school", this.formInline.school);//学校
                    dataobj.append("name", this.formInline.name);//名字
                    dataobj.append("page", this.page);
                    dataobj.append("limit", this.pageSize);
                    this.loading = true;
                    userInfo.certificate(dataobj).then(res => {
                        this.loading = false;
                        this.data = JSON.parse(res.data);
                        this.total = res.count;
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //切换条数
        handleSizeChange(val) {
            
            this.$refs['formInline'].validate((valid) => {
                if (valid) {
                    this.pageSize = val;
                    this.query();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        //切换页
        handleCurrentChange(val) {
            
            this.$refs['formInline'].validate((valid) => {
                if (valid) {
                    this.page = val;
                    this.query();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
}
</script>

<style scoped lang="scss">
@import './Certificate.scss'
</style>